import React, { useState } from "react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./ProductPage.css";
import tabLogo from "../../assets/pure-tab-with-logo.png";
import Modal from "./Modal/Modal";

const deviceImages = {
  G65: "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fdevices-all%2Fdialin%20phone-kosher-logo%202.png?alt=media&token=6f17b310-7aff-4bf1-a889-c02674fa53ba",
  G10: tabLogo,
  MaxMusic:
    "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2FMax%20music_with%20apps-med-min.png?alt=media&token=6227d429-d5f7-4c78-a502-68cf729a3d76",
  Nova: "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2Foutput-onlinepngtools.png?alt=media&token=63ea2af3-cacd-47a1-a9ea-7315b508037a",
};

const deviceSpecs = {
  G65: [
    '6.5" HD+ Display',
    "2 GHz Quad-Core Processor",
    "32 GB Internal Memory + 3 GB RAM",
    "Face Unlock",
    "8 MP Camera",
    "5 MP Selfie Camera",
    "4000 mAh Battery",
    "Micro SD Up to 256 GB",
    "Powered by Android 13",
    "Includes: Rugged Case + Type-C Data Cable",
  ],
  G10: [
    '10" HD+ Display',
    "Octa-Core Processor",
    "64 GB Internal Memory + 4 GB RAM",
    "8 MP Camera",
    "5000 mAh Battery",
    "Micro SD Up to 256 GB",
    "Powered by Android 13",
    "Metal Back Cover",
    "Includes: Rugged Case + Charger + Type-C Data Cable",
  ],
  Nova: [
    '6.5" HD+ Display',
    "2 GHz MediaTek Quad-Core Processor",
    "64 GB Internal Memory + 4 GB RAM",
    "13 MP Camera",
    "4000 mAh Battery",
    "Micro SD Up to 256 GB",
    "Powered by Android 13",
    "Includes: Rugged Case + Type-C Data Cable",
  ],
  MaxMusic: [
    "Customized Music OS",
    "Supports apps like 24Six, Zing, Naki Go, J Stream",
    "256 MB Storage",
    "Wi-Fi and Bluetooth Enabled",
  ],
};

const ProductPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentSpecs, setCurrentSpecs] = useState([]);

  const handleMoreDetails = (device) => {
    setCurrentSpecs(deviceSpecs[device]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateToMobileSelection = (deviceName) => {
    navigate("/device-selection", { state: { deviceName } });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product-page">
      <div className="product-hero-section">
        <div className="product-hero-content">
          <h1>Choose Your Kosher Device</h1>
          <p>
            Select from our range of certified kosher devices designed for your
            needs
          </p>
        </div>
      </div>

      <div className="product-grid">
        {/* G65 Device Card */}
        <div className="product-card">
          <div className="product-image">
            <img src={deviceImages.G65} alt="G65 Smartphone" />
          </div>
          <center>(T-Mobile Only)</center>
          <div className="product-details">
            <h2>G65 Smartphone</h2>
            <div className="product-price">
              <span className="original-price">$350</span>
              <span className="current-price">$99.99</span>
              <span className="monthly-price">
                or $9.99/month for 12 months
              </span>
            </div>
            <ul className="product-features">
              <li>Customized OS</li>
              <li>Kosher App Store</li>
              <li>Sim-Locked to Device</li>
              <li>8 MP Camera</li>
              <li>Powered by Android 13</li>


            </ul>
            <button
              onClick={() => handleMoreDetails("G65")}
              className="product-cta more-details"
            >
              More Details
            </button>
           

            <button
              onClick={() => navigateToMobileSelection("G65")}
              className="product-cta"
            >
              Choose Device <span>→</span>
            </button>
          </div>
        </div>

 

        {/* Nova Device Card */}
        <div className="product-card">
          <div className="product-image">
            <img src={deviceImages.Nova} alt="Nova" />
          </div>
          <center>(T-Mobile and AT&T)</center>
          <div className="product-details">
            <h2>Nova Smartphone</h2>
            <div className="product-price">
              <span className="original-price">$350</span>
              <span className="current-price">$99.99</span>
              <span className="monthly-price">
                or $9.99/month for 12 months
              </span>
            </div>
            <ul className="product-features">
              <li>Customized OS</li>
              <li>Kosher App Store</li>
              <li>Sim-Locked to Device</li>
              <li>13 MP Camera</li>
              <li>Powered by Android 13</li>

            </ul>
            <button
              onClick={() => handleMoreDetails("Nova")}
              className="product-cta more-details"
            >
              More Details
            </button>
            <button
              onClick={() => navigateToMobileSelection("Nova")}
              className="product-cta"
            >
              Choose Device <span>→</span>
            </button>
          </div>
        </div>




        {/* Max Music Device Card */}
        <div className="product-card">
          <div className="product-image">
            <img src={deviceImages.MaxMusic} alt="Max Music" />
          </div>
          <center><div class="spacer"></div>
          </center>

          <div className="product-details">
            <h2>Max Music</h2>
            <div className="product-price">
              <span className="original-price">$150</span>
              <span className="current-price">$99.99</span>
              <span className="monthly-price">
                or $9.99/month for 12 months
              </span>
            </div>
            <ul className="product-features">
              <li>24Six</li>
              <li>Zing</li>
              <li>Naki Go</li>
              <li>J Stream</li>
              <li>& many more</li>
            </ul>
            <button
              onClick={() => handleMoreDetails("MaxMusic")}
              className="product-cta more-details"
            >
              More Details
            </button>
            <button
              onClick={() => navigateToMobileSelection("MaxMusic")}
              className="product-cta"
            >
              Choose Device <span>→</span>
            </button>
          </div>
        </div>

               {/* G10 Device Card */}
               <div className="product-card">
          <div className="product-image">
            <img src={deviceImages.G10} alt="G10 Tablet" />
          </div>
          <center><div class="spacer"></div>
          </center>

          <div className="product-details">
            <h2>G10 Tablet</h2>
            <div className="product-price">
              <span className="original-price">$350</span>
              <span className="current-price">$99.99</span>
              <span className="monthly-price">
                or $9.99/month for 12 months
              </span>
            </div>
            <ul className="product-features">
              <li>Customized OS</li>
              <li>Kosher App Store</li>
              <li>Sim-Locked to Device</li>
              <li>8 MP Camera</li>
              <li>Powered by Android 13</li>
            </ul>
            <button
              onClick={() => handleMoreDetails("G10")}
              className="product-cta more-details"
            >
              More Details
            </button>
            <button
              onClick={() => navigateToMobileSelection("G10")}
              className="product-cta"
            >
              Choose Device <span>→</span>
            </button>
          </div>
        </div>
      </div>

      <Footer />

      {/* Modal for displaying specifications */}
      <Modal show={showModal} onClose={closeModal} specs={currentSpecs} />
    </div>
  );
};

export default ProductPage;

