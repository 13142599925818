import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <>
      <footer className="footer-distributed">
        <div className="footer-left">
          <div className="mobile-view-footer-logo">
            maxsip <span>PURE</span>
          </div>
          <p className="footer-links">
            <a href="#" className="link-1">
              Home
            </a>
            <a href="/about-us">About Us</a>
            <a href="/support">FAQ</a>
            <a href="/about-us">Contact</a>
          </p>
        </div>

        <div className="footer-center">
          <div>
            <i className="fa fa-map-marker"></i>
            <p>
              <span>P.O BOX 351 Valley Stream, NY 11582</span>
            </p>
          </div>

          <div>
            <i className="fa fa-phone"></i>
            <p>(516) 866-7321</p>
          </div>

          <div>
            <i className="fa fa-envelope"></i>
            <p>
              <a href="mailto:Support@maxsippure.com">Support@maxsippure.com</a>
            </p>
          </div>
          <div>
            <i className="fa fa-envelope"></i>
            <p>
              <a>v3.0</a>
            </p>
          </div>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>About the company</span>
            Maxsip Telecom provides communication solutions that are affordable and accessible to all. As a leading telecommunications provider, Maxsip Telecom ensures that everyone can stay connected.
          </p>

          <div className="footer-icons">
            <a href="https://www.facebook.com/MaxsipTel/">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/maxsiptel/?hl=en">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.linkedin.com/company/maxsiptel/">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
