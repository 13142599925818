import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./DeviceSelectionPage.css";
import Swal from "sweetalert2";

// Importing device images
import mobile1 from "../../assets/all_phones/white.png";
import mobile2 from "../../assets/all_phones/navy.png";
import mobile3 from "../../assets/all_phones/pink.png";
import mobile4 from "../../assets/all_phones/turq.png";
import mobile5 from "../../assets/all_phones/grey.png";
import mobile6 from "../../assets/dialin phone-kosher-logo 2.png";

// Default images by color for general devices
const imagesByColor = {
  color1: mobile1,
  color2: mobile2,
  color3: mobile3,
  color4: mobile4,
  color5: mobile5,
  color6: mobile6,
};

// Specific image URLs for G10 and Nova
const g10ImageURL =
  "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Fdevices-all%2Fpure-tab-with-logo.png?alt=media&token=63264ecf-abe7-4c80-974c-0ff22f58ee81";
const novaImageURL =
  "https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2Foutput-onlinepngtools.png?alt=media&token=63ea2af3-cacd-47a1-a9ea-7315b508037a";

const DeviceSelectionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the device name from URL parameters or default to "G65"
  const { deviceName = "G65" } = location.state || {};

  // Set the default color based on device
  const initialColor =
    deviceName === "G10" || deviceName === "Nova" ? "Black" : "White";

  const [selectedColor, setSelectedColor] = useState(initialColor);
  const [selectedBuyOption, setSelectedBuyOption] = useState("");
  const [SelectedPlanPrice, setSelectedPlanPrice] = useState("");
  const [devicePrice, setDevicePrice] = useState("");
  const [currentImage, setCurrentImage] = useState(
    deviceName === "G10"
      ? g10ImageURL
      : deviceName === "Nova"
      ? novaImageURL
      : imagesByColor["color1"]
  );

  // Determine color options and images based on device name
  const colorOptions =
    deviceName === "G10" || deviceName === "Nova"
      ? [{ name: "Black", code: "#353432", variable: "black" }]
      : [
          {
            name: "Black",
            code: "#353432",
            variable: "color6",
            soldOut: false,
          },
          {
            name: "White",
            code: "#fefeff",
            variable: "color1",
            soldOut: false,
          },
          { name: "Turq", code: "#7796b8", variable: "color4", soldOut: false },
          { name: "Pink", code: "#f7ddff", variable: "color3", soldOut: false },
          { name: "Navy", code: "#6092b1", variable: "color2", soldOut: false },
          { name: "Grey", code: "#6c6e81", variable: "color5", soldOut: false },
        ];

  useEffect(() => {
    const savedState = localStorage.getItem("mobileSelectionState");
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setSelectedColor(parsedState.selectedColor);
      setSelectedBuyOption(parsedState.selectedBuyOption);
      setSelectedPlanPrice(parsedState.SelectedPlanPrice);
      setDevicePrice(parsedState.devicePrice);
      setCurrentImage(parsedState.currentImage);
    }

    window.scrollTo(0, 0);
  }, [deviceName]);

  useEffect(() => {
    const state = {
      selectedColor,
      selectedBuyOption,
      SelectedPlanPrice,
      deviceName,
      currentImage,
    };
    localStorage.setItem("mobileSelectionState", JSON.stringify(state));
  }, [
    selectedColor,
    selectedBuyOption,
    SelectedPlanPrice,
    deviceName,
    currentImage,
  ]);

  const handleContinue = () => {
    if (!selectedColor || !selectedBuyOption || !SelectedPlanPrice) {
      Swal.fire({
        title: "Missing Selection",
        text: "Please make sure all selections are made before continuing.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (localStorage.getItem("checkOutState")) {
      localStorage.removeItem("checkOutState");
    }

    navigate("/checkout", {
      state: {
        selectedColor,
        selectedBuyOption,
        SelectedPlanPrice,
        deviceName,
        devicePrice,
      },
    });
  };

  const selectedBuyOptions = (option) => {
    setSelectedBuyOption(option);
    setDevicePrice(option === "Buy" ? "99.99" : "9.99");
  };

  const handlePlanSelection = (price) => {
    setSelectedPlanPrice(`$${price}`);
  };

  // Update image and color when color changes
  const handleColorSelection = (color) => {
    setSelectedColor(color.name);
    setCurrentImage(
      deviceName === "G10"
        ? g10ImageURL
        : deviceName === "Nova"
        ? novaImageURL
        : imagesByColor[color.variable]
    );
  };

  return (
    <div className="mobile-selection-page">
      <div className="mobile-hero-section">
        <div className="mobile-hero-content">
          <h1>Customize Your {deviceName} Smartphone</h1>
          <p>Select your preferences to get started with your kosher device</p>
        </div>
      </div>

      <div className="mobile-selection-container">
        <div className="mobile-preview">
          <div className="mobile-image-container">
            <img
              src={currentImage}
              alt={`${deviceName} Smartphone`}
              className="mobile-image"
            />
          </div>
          <div className="selected-options">
            <h3>Your Selection</h3>
            <div className="selection-item">
              <span>Color:</span>
              <span>{selectedColor || "Not selected"}</span>
            </div>
            <div className="selection-item">
              <span>Payment Option:</span>
              <span>
                {selectedBuyOption === "Buy"
                  ? "$99.99"
                  : selectedBuyOption === "option2"
                  ? "$9.99/mon."
                  : "Not selected"}
              </span>
            </div>
            <div className="selection-item">
              <span>Plan Price:</span>
              <span>{SelectedPlanPrice || "Not selected"}</span>
            </div>
          </div>
        </div>

        <div className="mobile-options">
          <div className="options-row">
            <section className="option-section">
              <h2>Choose Color</h2>
              <div className="color-options">
                {colorOptions.map((color) => (
                  <button
                    key={color.name}
                    className={`color-button ${
                      selectedColor === color.name ? "selected" : ""
                    }`}
                    style={{ backgroundColor: color.code }}
                    onClick={() => handleColorSelection(color)}
                  >
                    <span className="color-name">{color.name}</span>
                  </button>
                ))}
              </div>
            </section>

            <section className="option-section">
              <h2>Payment Options</h2>
              <div className="payment-options">
                <button
                  className={`option-button ${
                    selectedBuyOption === "Buy" ? "selected" : ""
                  }`}
                  onClick={() => selectedBuyOptions("Buy")}
                >
                  <h3>One Time Payment</h3>
                  <p className="price">$99.99</p>
                  <p className="description">Pay with debit or credit card</p>
                </button>
                <button
                  className={`option-button ${
                    selectedBuyOption === "option2" ? "selected" : ""
                  }`}
                  onClick={() => selectedBuyOptions("option2")}
                >
                  <h3>Monthly Payment</h3>
                  <p className="price">$9.99/month</p>
                  <p className="description">12-month financing at 0% APR</p>
                </button>
              </div>
            </section>

            <section className="option-section">
              <h2>Choose Your Plan</h2>
              <div className="plan-options">
                <div className="plan-type">
                  <h3>Maxsip Plans</h3>
                  <div className="data-plans">
                    <button
                      className={`option-button ${
                        SelectedPlanPrice === "$15" ? "selected" : ""
                      }`}
                      onClick={() => handlePlanSelection("15")}
                    >
                      <h4>1GB</h4>
                      <p className="price">$15/month</p>
                    </button>
                    <button
                      className={`option-button ${
                        SelectedPlanPrice === "$20" ? "selected" : ""
                      }`}
                      onClick={() => handlePlanSelection("20")}
                    >
                      <h4>3GB</h4>
                      <p className="price">$20/month</p>
                    </button>
                    <button
                      className={`option-button ${
                        SelectedPlanPrice === "$25" ? "selected" : ""
                      }`}
                      onClick={() => handlePlanSelection("25")}
                    >
                      <h4>5GB</h4>
                      <p className="price">$25/month</p>
                    </button>
                    <button
                      className={`option-button ${
                        SelectedPlanPrice === "$35" ? "selected" : ""
                      }`}
                      onClick={() => handlePlanSelection("35")}
                    >
                      <h4>10GB</h4>
                      <p className="price">$35/month</p>
                    </button>
                  </div>
                </div>

                <div className="plan-type">
                  <h3>Use Your Own Carrier</h3>
                  <button
                    className={`option-button carrier-option ${
                      SelectedPlanPrice === "$9.99" ? "selected" : ""
                    }`}
                    onClick={() => handlePlanSelection("9.99")}
                  >
                    <h4>Connect Existing Carrier</h4>
                    <p className="price">
                      <span className="original-price">$15</span>
                      <span className="current-price">$9.99/mon.</span>
                    </p>
                    <p className="description">for kosher services</p>
                  </button>
                </div>
              </div>
            </section>
          </div>

          {(selectedColor || selectedBuyOption || SelectedPlanPrice) && (
            <button className="continue-button" onClick={handleContinue}>
              Continue to Checkout <span>→</span>
            </button>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DeviceSelectionPage;
