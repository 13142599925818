import React, { useEffect, useState } from "react";
import "./InitialPopUpMenu.css";
import { firestore } from "../../../firebaseConfig";
import { setDoc, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const InitialPopUpMenu = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: ""
  });
  const [claimed, setClaimed] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setSuccess(false);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    if (!phone) return true; // Phone is optional
    const re = /^\+?[\d\s-]{10,}$/;
    return re.test(phone);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(""); // Clear error when user types
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address");
      setIsSubmitting(false);
      return;
    }

    if (!validatePhone(formData.phone)) {
      setError("Please enter a valid phone number or leave it empty");
      setIsSubmitting(false);
      return;
    }

    const discountEmailData = {
      email: formData.email,
      phone: formData.phone,
      timestamp: serverTimestamp(),
      claimed: true
    };

    try {
      const docRef = doc(firestore, "discount-emails", formData.email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setError("This email has already claimed the discount");
        localStorage.removeItem("isDiscountEligible");
      } else {
        await setDoc(docRef, discountEmailData);
        localStorage.setItem("isDiscountEligible", "true");
        setSuccess(true);
        setFormData({ email: "", phone: "" });
        setIsVisible(false);
      }
    } catch (err) {
      setError("Unable to process your request. Please try again later.");
      console.error("Error processing discount claim:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectDevice = () => {
    navigate("/our-products");
  };

  if (!isVisible && !success) return null;

  return (
    <div className="popup-container">
      {!success ? (
        <div className="popup-content">
          <button className="close-button" onClick={handleClose} aria-label="Close">
            ×
          </button>
          
          <div className="popup-header">
            <h2>Special Offer!</h2>
            <div className="thank-u-text">
              Welcome to Maxsip Pure
            </div>
          </div>

          <p className="offer-text">
            Provide email and phone number to get <strong>30% Discount</strong>.
            <br />
          </p>

          {error && <div className="error-message">{error}</div>}

          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              required
              aria-label="Email address"
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone number (Optional)"
              value={formData.phone}
              onChange={handleInputChange}
              aria-label="Phone number"
            />
            <button 
              type="submit" 
              className="claim-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Processing..." : "Send"}
            </button>
          </form>

          <div className="no-thanks" onClick={handleClose}>
            Maybe later
          </div>
        </div>
      ) : (
        <div className="popup-content">
          <button className="close-button" onClick={handleClose} aria-label="Close">
            ×
          </button>
          
          <h2>Congratulations!</h2>
          <div className="success-message">
            Your discount has been successfully claimed.
          </div>
          
          <button
            className="select-device-button"
            onClick={handleSelectDevice}
          >
            Choose Your Device
          </button>
        </div>
      )}
    </div>
  );
};

export default InitialPopUpMenu;
