import React, { useState, useEffect } from "react";
import { firestore2 } from "../../firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import "./KosherAppStore.css";
import Swal from "sweetalert2";
import universalFunctions from "../../Utils/universalFunctions";
import { useNavigate } from "react-router-dom";


const KosherAppStore = () => {

  const navigate = useNavigate();

  const [imei, setImei] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [apps, setApps] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [orders, setOrders] = useState([]);

  const fetchApps = async () => {
    const appsCollection = collection(firestore2, "apps");
    const snapshot = await getDocs(appsCollection);
    const appsList = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    setApps(appsList);
    console.log("Fetched apps:", appsList);
  };

  useEffect(() => {
    fetchApps();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDragStart = (event, app) => {
    console.log(`Dragging app: ${JSON.stringify(app)}`);
    event.dataTransfer.setData("application/json", JSON.stringify(app));
    setTimeout(() => {
      event.target.classList.add("is-active");
    }, 0);
  };

  const handleDragEnd = (event) => {
    event.target.classList.remove("is-active");
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    const mobileFrame = document.querySelector(".mobile-frame");
    mobileFrame.classList.add("drag-hover");
  };

  const handleDragEnter = () => {
    const mobileFrame = document.querySelector(".mobile-frame");
    mobileFrame.classList.add("drag-hover");
  };

  const handleDragLeave = () => {
    const mobileFrame = document.querySelector(".mobile-frame");
    mobileFrame.classList.remove("drag-hover");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleDragLeave();
    const appData = event.dataTransfer.getData("application/json");
    const app = JSON.parse(appData);
    console.log(`Dropped app: ${JSON.stringify(app)}`);
    if (app && !droppedItems.find((item) => item.id === app.id)) {
      setDroppedItems((prevItems) => {
        const newItems = [...prevItems, app];
        console.log(`New droppedItems: ${newItems}`);
        return newItems;
      });
      setApps((prevApps) => prevApps.filter((a) => a.id !== app.id));
    } else {
      console.log("Item already dropped or app not found");
    }
    // Add to orders list
    setOrders((prevOrders) => [...prevOrders, app.id.replace(/_/g, ".")]);
  };

  const handleRemove = (id) => {
    const app = droppedItems.find((item) => item.id === id);
    if (app) {
      setDroppedItems((prevItems) =>
        prevItems.filter((item) => item.id !== id)
      );
      setApps((prevApps) => [app, ...prevApps]);
      setOrders((prevOrders) =>
        prevOrders.filter((bundleId) => bundleId !== id.replace(/_/g, "."))
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const orderData = {
        imei,
        apps: orders,
        timestamp: serverTimestamp(),
      };
      setOrders([]);
      setDroppedItems([]);
      fetchApps();
      universalFunctions.saveOrderDataToLocalStorage(orderData);
      navigate("/our-products")
   
    } catch (error) {
      console.error("Error submitting order:", error);
      alert("Error submitting order.");
    }
  };

  // Function to group apps by categories with priority order
  const groupAppsByCategory = (apps) => {
    const priorityCategories = [
      "Business",
      "Educational",
      "Banking",
      "Medical",
      "Travel",
      "Finance",
      "Tools",
      "Entertainment",
      "Transportation",
      "Social",
      "Torah",
    ];
    const grouped = apps.reduce((acc, app) => {
      const category = app.data.category
        ? app.data.category.toLowerCase()
        : "other";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(app);
      return acc;
    }, {});

    const orderedGrouped = {};
    priorityCategories.forEach((category) => {
      const lowerCategory = category.toLowerCase();
      if (grouped[lowerCategory]) {
        orderedGrouped[category] = grouped[lowerCategory];
      }
    });

    orderedGrouped["Other"] = grouped["other"] || [];

    return orderedGrouped;
  };

  const groupedApps = groupAppsByCategory(apps);

  return (
    <div className="app-store-builder">
      <h1>App Store Builder</h1>
      <p>
        Drag and drop desired apps into the device to add to your personalized
        app store.
      </p>
      <div className="app-store-upper-container">
        <div className="left-upper-container">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search for apps"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="app-store-container">
        <div className="app-list">
          {Object.entries(groupedApps).map(([category, apps]) => (
            <div key={category}>
              <h2>{category}</h2>
              <div className="app-category-list">
                {apps
                  .filter((app) =>
                    app.data.appName.toLowerCase().includes(searchTerm)
                  )
                  .map((app) => (
                    <div
                      className="app-item"
                      key={app.id}
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, app)}
                      onDragEnd={handleDragEnd}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <img src={app.data.logoUrl} alt={app.data.appName} />
                        <div className="app-name-slider">
                          <span>{app.data.appName}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className="drop-area"
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="mobile-frame">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2Fphone20%25op.png?alt=media&token=d8880fc7-2e15-4f44-a3c0-9bdfbc1a11b2"
              className="frame-image"
              alt="mobile frame"
            />
            <div className="dropped-items-container">
              {droppedItems.map((app) => (
                <div className="dropped-item" key={app.id}>
                  <img src={app.data.logoUrl} alt={app.data.appName} />
                  <div
                    className="delete-btn"
                    onClick={() => handleRemove(app.id)}
                  >
                    ×
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="imei"></label>
        <input
          type="hidden"
          id="droppedItems"
          name="droppedItems"
          value={droppedItems.map((item) => item.id).join(",")}
        />
   
        <button type="submit">Click Here to Choose Device</button>
      </form>
    </div>
  );
};

export default KosherAppStore;
